@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*, :after, :before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Oswald', sans-serif;
}

p, a, span {
    font-family: 'Open Sans', sans-serif;
}

a {
    text-decoration: none;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    z-index: 100;
    padding: 0.25rem 0;
    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .nav-links {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                margin-right: 3%;
            }
        }

        a {
            color: #000;
            margin-right: 2%;
            text-transform: uppercase;
            font-size: 15px;

            @media screen and (max-width:400px) {
                font-size: 13px;
            }

        }

        .nav-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2.5%;
            width: 40%;

            @media screen and (max-width:800px) {
                span {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    font-size: 11px;
                }
            }



            span {
                margin-right: 5%;
                padding: 2px;
                background: #000;
                color: #fff;
            }

            .nav-content {
                a {
                    display: flex;
                    align-items: center;

                    @media screen and (max-width:600px) {
                        font-size: 0;
                    }
                }
                img {
                    width: 28px;
                    margin-right: 5%;
                }

                p {
                    font-size: 14px;

                    @media screen and (max-width:800px) {
                        position: fixed;
                        bottom: 0;
                        right: 0;
                        font-size: 11px;
                        background: #000;
                        color: #fff;
                        padding: 2px;
                    }
                }
            }
        }
    }
}

.logo {
    width: 100%;
    max-width: 100px;

    @media screen and (max-width:600px) {
        max-width: 55px;
    }
}

.viewport {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.scroll-container {  
  position: absolute;
  width: 100%;  
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
//   flex-direction: column;  
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.content {
  width: 100%; 
  padding-top:50px;
}

.img-container {
  margin: 0 0 50px;
  
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.hero {
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url('./assets/hero.jpg');
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;

    h2 {
        color: #fefefe;
        font-size: 4rem;
        z-index: 1;
        
    }

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.4);
    }

    @media screen and (max-width:800px) {
        height: 92vh;
        background-image: url('./assets/hero-mobile.jpg');

        h2 {
            font-size: 2.5rem;
            text-align: center;
        }
    }
}

.section-header {
    font-size: 3vw;
    text-align: center;
    padding: 0.8em 0 0.5em;

    @media screen and (max-width:800px) {
        font-size: 2rem;
    }
}

.services {
    width: 100%;
    background-color: #f6f4ed;

    &-list {
        width: 70%;
        margin: 2em auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width:800px) {
            width: 90%;
        }

        .service {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 3em;

            @media screen and (max-width:800px) {
                width: 48%;
            }

            img {
                width: 100%;
                max-width: 120px;

                @media screen and (max-width:800px) {
                    max-width: 90px;
                }
            }

            h3 {
                font-size: 1.25rem;
                margin-bottom: 0.5rem;

                @media screen and (max-width:800px) {
                    margin-top: 0.5rem;
                    text-align: center;
                    font-size: 0.95rem;
                }
            }

            p {
                padding: 0 20%;
                text-align: center;

                @media screen and (max-width:800px) {
                    padding: 0 0%;
                    font-size: 14px;
                }
            }
        }
    }    
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #7BC3DA!important;
}

.swiper-button-prev, .swiper-button-next {
    color: #7BC3DA!important;
}

.swiper {
    width: 80%;
    height: 70vh;
    overflow: hidden;

    @media screen and (max-width:800px) {
        width: 100%;
        height: 60vh;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .footer-logo {
        width: 100%;
        max-width: 125px;
    }

    h2 {
        font-size: 20px;
    }
}

//formularz

.contain {

    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;

    @media screen and (max-width:800px) {
        width: 90%;
        padding: 5px;
    }
  }
  
  div.form {
    
  }
  .contact-wrapper {
    margin: auto 0;
  }
  
  .submit-btn {
    float: left;
  }
  .reset-btn {
    float: right;
  }
  
  .form-headline:after {
    content: "";
    display: block;
    width: 10%;
    padding-top: 10px;
    border-bottom: 3px solid #7bc3da;
  }
  
  .highlight-text a {
    color: #7bc3da;
  }
  
  .hightlight-contact-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
  }
  
  .highlight-text-grey {
    font-weight: 500;
  }
  
  .email-info {
      margin-top: 20px;
  }
  
  ::-webkit-input-placeholder { /* Chrome */
    font-family: 'Roboto', sans-serif;
  }
  
  .required-input {
    color: black;
  }
  @media (min-width: 600px) {
    .contain {
      padding: 0;
    }
  }
  
  h3,
  ul {
    margin: 0;
  }
  
  h3 {
    margin-bottom: 1rem;
  }
  
  .form-input:focus,
  textarea:focus{
    outline: 1.5px solid #7bc3da;
  }
  
  .form-input,
  textarea {
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }
  
  .wrapper > * {
    padding: 1em;
  }
  @media (min-width: 700px) {
    .wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    .wrapper > * {
      padding: 2em 2em;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .contacts {
    color: #212d31;
  }
  
  .form {
    background: transparent;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 2em;
  }
  form label {
    display: block;
  }
  form p {
    margin: 0;
  }
  
  .full-width {
    grid-column: 1 / 3;
  }
  
  button,
  .submit-btn,
  .form-input,
  textarea {
    padding: 1em;
  }
  
  button, .submit-btn {
    background: transparent;
    border: 1px solid #7bc3da;
    color: #7bc3da;
    border-radius: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.35s ease;

    @media screen and (max-width:800px) {
        padding: 15px 35px;
        border-radius: 25px;
    }
  }
  button:hover, .submit-btn:hover,
  button:focus , .submit-btn:focus{
    background: #7bc3da;
    outline: 0;
    color: #eee;
  }
  .error {
    color: #ec1c24;
  }

//formularz


.contact {
    margin-top: 5em;
    margin-bottom: 3em;
    background-color: #f6f4ed;
}

.year {
    margin-bottom: 2em;
}